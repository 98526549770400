import React from "react";

import Image from "next/image";
import Link from "next/link";

import dynamic from "next/dynamic";
const Button = dynamic(() => import("@mui/material/Button"));

import { serviceProps } from "../../helperFunctions/getServices";

import styles from "./service.module.scss";

function Service({ imageSrc, text, alt, title, route }: serviceProps) {
  return (
    <Link href={route} passHref title={title}>
      <div className={styles["single-service"]}>
        <div className={styles["service-bg"]}>
          <Image
            src={imageSrc}
            title={title}
            width={350}
            alt={alt}
            quality={50}
            style={{
              objectFit: "cover",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          />
        </div>
        <div className={styles["service-text"]}>
          <p>{text}</p>
        </div>
        <div className={styles["service-button"]}>
          <Button size="large" variant="outlined" style={{ width: "80%" }}>
            {title}
          </Button>
        </div>
      </div>
    </Link>
  );
}

export default Service;
